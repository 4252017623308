// CSS for Navbar

export const styles = {
    "link-enabled" : {
        "color" : 'white'
    },
    "link-disabled" : {
        "color" : 'grey'
    },
    "AI-Art-link" : {
        'color': 'yellow', 'font-weight': 'bold'
    }
}