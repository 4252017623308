// CSS for Logout Page
export const styles = {
    "logout-page-jumbotron" : {
        'padding-top': '2.5rem', 
        'padding-bottom': '2.5rem', 
        'background-color': '#EAFCFC'
    },
    "home-button" : {
        'margin-top': '2rem'
    }
}